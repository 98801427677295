<template>
  <div class="tab-one tab-content-detail">
    <div class="account-overview-block">
      <div class="heading">{{ $t("my_account_overview") }}</div>
      <div class="sub-heading"></div>
      <div class="hello-box">
        <div class="left-h">
          <h1 class="hello-box-title">
            {{ $t("hello") }} {{ user.firstname }}!
          </h1>
          <p class="hello-box-sub-title">{{ $t("your_account_overview") }}</p>
        </div>
        <div class="left-h hello-box-img">
          <b-img :src="logo" alt="" />
        </div>
      </div>
    </div>
    <div class="my-latest-order-block">
      <div class="heading bold">{{ $t("your_latest_orders") }}</div>
      <div
        class="product-carousel-box myaccount-latest-order"
        v-if="myOrders && myOrders.length"
      >
        <VueSlickCarousel :ref="'slick'" v-bind="settings_acc_slider">
          <div class="product_acc" v-for="order in myOrders" :key="order.id">
            <b-card-body class="acc-product-slider">
              <div class="product_acc_inner">
                <b-link to="/product/"
                  ><b-img
                    :alt="order.items[0].product_name"
                    :src="order.items[0].image.url"
                /></b-link>
              </div>
              <div class="product-acc-content">
                <h4
                  class="product_hd bold"
                  :class="order.status === 'Pending' ? 'red' : 'success'"
                >
                  {{ order.status }}
                </h4>
                <p>{{ order.items[0].product_name }}</p>
              </div>
            </b-card-body>
          </div>
        </VueSlickCarousel>
      </div>
      <div class="download-link d-pt-15">
        <b-link class="text-decoration" @click="GoToTab(2)"
          >{{ $t("view_all_orders") }}
        </b-link>
      </div>
    </div>

    <div class="invoices-block">
      <div class="heading bold pb-10">{{ $t("invoices") }}</div>
      <div class="sub-heading">
        {{ $t("invoice_information") }}
      </div>
      <div class="download-link">
        <!-- <b-link class="text-decoration" @click="GoToTab(3)"
          >{{ $t("view_invoices") }}
        </b-link> -->
        <b-link class="text-decoration">{{ $t("view_invoices") }} </b-link>
      </div>
    </div>

    <div class="infor-preferences-block">
      <div class="heading pb-head-10">
        {{ $t("information_preferences") }}
      </div>
      <div class="product-acc-block">
        <div class="left-h">
          <div class="heading bold pb-20">{{ $t("your_information") }}</div>
          <div class="product-acc-content">
            <div v-if="user.addresses && user.addresses.length > 0">
              <p v-if="user.addresses[0].company != null">
                {{ user.addresses[0].company }}
              </p>
              <p>
                {{ user.addresses[0].firstname }}
                {{ user.addresses[0].lastname }}
              </p>
              <p>{{ user.addresses[0].street.join(" ") }}</p>
              <p>{{ user.addresses[0].postcode }}</p>
              <p>{{ user.addresses[0].city }}</p>
            </div>
          </div>
        </div>
        <div class="left-h product-acc-img">
          <b-img :src="logo" alt="smilexl-logo" class="pt-20" />
        </div>
      </div>
      <div class="download-link">
        <b-link class="text-decoration" @click="GoToTab(1)"
          >{{ $t("administrate_your_information") }}
        </b-link>
      </div>
    </div>

    <div
      class="product-img-carousel-box pb-10"
      v-if="wishlist && wishlist.length"
    >
      <div class="heading bold">{{ $t("your_wishlist") }}</div>
      <VueSlickCarousel v-bind="settings_acc_slider_second">
        <div class="product_acc" v-for="item in wishlist" :key="item.id">
          <b-card-body class="acc-product-slider">
            <span @click="removeFromWishList(item.product.sku)"
              ><font-awesome-icon icon="times"
            /></span>
            <div class="product_acc_inner">
              <router-link :to="'/' + item.product.url_key + '/'"
                ><b-img :alt="item.product.name" :src="item.product.image.url"
              /></router-link>
            </div>
          </b-card-body>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="download-link">
      <b-link class="text-decoration" @click="GoToTab(3)"
        >{{ $t("view_your_wishlist") }}
      </b-link>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import logo from "@/esf_utrecht_bankxl/assets/images/BankXL_logo.png";
import smilexl from "@/esf_utrecht_bankxl/assets/images/smilexl.png";
import smilexlSm from "@/esf_utrecht_bankxl/assets/images/smilexl-sm.png";
import door_img1 from "@/esf_utrecht_bankxl/assets/images/door1.png";
import door_img from "@/esf_utrecht_bankxl/assets/images/door.png";
export default {
  name: "AccountTabOverview",
  props: {
    msg: String,
  },
  components: { VueSlickCarousel },
  computed: {
    wishlist() {
      return this.$store.getters["wishlist/getWishItems"];
    },
    myOrders() {
      return this.$store.getters["user/getMyOrders"];
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
  },
  methods: {
    GoToTab(index) {
      this.tabIndex = index;
    },
    async removeFromWishList(sku) {
      await this.$store.dispatch("wishlist/removeProductFromWishlist", { sku });
    },
  },
  data() {
    return {
      //first slider
      logo,
      smilexlSm,
      smilexl,
      door_img1,
      door_img,
      settings_acc_slider: {
        centerMode: false,
        dots: false,
        infinite: false,
        speed: 500,
        controls: true,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.5,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1.5,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
        ],
      },
      // second slider
      settings_acc_slider_second: {
        centerMode: false,
        dots: false,
        infinite: false,
        speed: 500,
        controls: true,
        slidesToShow: 4.3,
        slidesToScroll: 1,
        initialSlide: 0,
        rtl: false,
        responsive: [
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 3.5,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
