<template>
  <div class="product-detail">
    <div class="product-detail-inner">
      <Switcher
        v-if="switcherConfig"
        :SwitcherConfig="switcherConfig"
      ></Switcher>
      <div class="detail-inner-box-container">
        <b-modal
          ref="product-delete-wish"
          id="product-delete-wish"
          class="product-modal-inner"
          content-class="defaul-modal"
          hide-footer
          centered
          size="lg"
        >
          <template #modal-title class="bold"
            >{{ $t("remove_product_wishlist") }}
          </template>
          <div class="modal-text d-block border-bottom">
            <p>
              {{ $t("sure_remove_product_wishlist") }}
            </p>
            <!-- <p>Wil je deze producten bewaren voor later maak een account aan</p> -->
          </div>
          <div class="modal-btn-box pt-20">
            <b-button class="btn-success" block @click="clickOk">Ok</b-button>
            <b-button
              class="btn-success btn-outline"
              block
              @click="clickCancel"
              >{{ $t("cancel") }}</b-button
            >
          </div>
        </b-modal>
        <div class="detail-left">
          <div v-if="product.__typename == 'ConfigurableProduct'">
            <div v-for="parameter of options" :key="parameter.id">
              <div v-if="parameter.attribute_code == 'kleur'">
                <p class="dark color-title">{{ parameter.label }}:</p>
                <div class="color-variant">
                  <div
                    class="custom-variant"
                    v-for="opt of parameter.values"
                    :key="opt.value_index"
                  >
                    <input
                      type="radio"
                      :value="opt.value_index"
                      :id="`${parameter.attribute_code}-${opt.value_index}`"
                      :name="`${parameter.attribute_code}-select`"
                      v-on:click="
                        processOption(parameter.index, opt.value_index)
                      "
                    />
                    <label
                      :for="`${parameter.attribute_code}-${opt.value_index}`"
                    >
                      <div
                        class="variantCirle-wrap"
                        :style="{
                          borderColor: getColor(opt.value_index),
                        }"
                      >
                        <div
                          class="variantCirle"
                          :style="{
                            backgroundColor: getColor(opt.value_index),
                          }"
                        >
                          <font-awesome-icon icon="check" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div v-else class="extra-options-wrap">
                <p class="dark color-title">{{ parameter.label }}:</p>
                <div class="extra-options">
                  <span
                    v-for="opt of parameter.values"
                    :key="opt.value_index"
                    :class="{ selected: opt.value_index == currentChildSku }"
                  >
                    <b-button
                      :id="`${parameter.attribute_code}-${opt.value_index}`"
                      :name="`${parameter.attribute_code}-select`"
                      v-on:click="
                        processOption(parameter.index, opt.value_index)
                      "
                      class=""
                    >
                      <font-awesome-icon icon="check" class="btnTick" />&nbsp;
                      <span>
                        {{ opt.label }}
                      </span>
                    </b-button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="product-social-info">
            <ul>
              <li class>
                <b-link
                  class="warning"
                  :href="requestColorUrl"
                  target="_blank"
                  v-if="requestColorUrl"
                >
                  <font-awesome-icon icon="comment" />
                  {{ $t("request_color_sample") }}
                </b-link>
              </li>
              <li>
                <b-link class="danger" v-if="isInWishlist" @click="showPopup">
                  <b-icon icon="heart-fill" />
                  {{ $t("remove_from_favourites") }}
                </b-link>
                <b-link class="secondary" v-else @click="updateWish">
                  <b-icon icon="heart" />
                  {{ $t("add_to_wishlist") }}
                </b-link>
              </li>
            </ul>
            <ul>
              <li class>
                <b-link class="secondary" @click="scroll('other-product')">
                  <font-awesome-icon icon="eye" />
                  {{ $t("other_models") }}
                </b-link>
              </li>
              <li class>
                <b-link
                  class="secondary"
                  :href="`mailto:?body=${getCurrentUrl}`"
                >
                  <font-awesome-icon icon="envelope" />
                  {{ $t("email_this_page") }}
                </b-link>
              </li>
              <li class v-if="showroom">
                <font-awesome-icon icon="home" />
                {{ $t("visible_in_showroom") }}
              </li>
              <li class v-if="montage">
                <b-link v-b-modal.modalMontage class="secondary">
                  <font-awesome-icon icon="wrench" />
                  {{ $t("montage") }}
                </b-link>
              </li>
              <li>
                <DeliveryTime
                  :data="deliveryTime"
                  v-if="stockStatus !== 'IN_STOCK'"
                />
                <p class="stuck-info stuck-size bold pb-15" v-else>
                  <span
                    v-if="
                      product.stock_qty > product.stock_display_limit ||
                      product.stock_display_limit == null
                    "
                  >
                    <font-awesome-icon icon="truck" />
                    {{ $t("in_stock") }}
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="truck" />
                    {{ $t("x_left_in_stock", { qty: product.stock_qty }) }}
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <b-modal id="modalMontage" centered :title="modalText.title">
          <b-container fluid>
            <b-row>
              <b-col v-html="modalText.content"> </b-col>
            </b-row>
          </b-container>

          <template #modal-footer="{ ok }">
            <b-button size="lg" variant="success" @click="ok()">OK</b-button>
          </template>
        </b-modal>
        <div class="detail-right">
          <div class="product-detail-price">
            <div
              class="text-center lbLogo-main"
              :class="productBrand != null ? '' : 'opacity-null'"
            >
              <img
                v-if="productBrand"
                :src="productBrand.logo"
                :alt="productBrand.title"
                class="lb-logo"
              />
            </div>
            <div class="product-price-dtl">
              <span
                class="product-strike-text"
                v-if="regularPrice > finalPrice"
              >
                {{ formatCurrency(regularPrice) }}
              </span>
              <span class="product-stuk-text dark">
                <span class="stuk-text danger">
                  {{ formatCurrency(finalPrice) }}
                </span>
                &nbsp;

                <span v-if="productUnit">
                  {{ productUnit }}
                </span>
              </span>
            </div>
          </div>
          <div
            class="stock-cont-div desktop-show stock-value"
            v-if="product.__typename != 'GroupedProduct'"
          >
            <div class="number-count-inner">
              <span class="number-count">
                <input
                  class="stock-count"
                  type="number"
                  id="tentacles"
                  name="tentacles"
                  :value="quantity"
                  placeholder="1"
                  min="1"
                  max="100"
                  @change="changeQuantity"
                />
              </span>
              <span class="number-countarrow">
                <div class="count-up">
                  <font-awesome-icon icon="chevron-up" @click="quantityUp" />
                </div>
                <div class="count-down">
                  <font-awesome-icon
                    icon="chevron-down"
                    @click="quantityDown"
                  />
                </div>
              </span>
            </div>
            <span class>
              <b-button
                v-if="product.__typename != 'GroupedProduct'"
                @click="addToCart()"
                v-bind:disabled="btnDisable"
                :style="buttonClass"
                variant="success"
                class="big-btn"
              >
                <span>{{ $t("buy_product") }}</span>
                <span>
                  <font-awesome-icon icon="chevron-circle-right" />
                </span>
              </b-button>
            </span>
          </div>
        </div>
      </div>

      <div class="mobile-show product-detail-action">
        <div
          class="stock-cont-div"
          v-if="product.__typename != 'GroupedProduct'"
        >
          <div class="number-count-inner">
            <span class="number-count">
              <input
                class="stock-count"
                type="number"
                id="tentacles"
                name="tentacles"
                :value="quantity"
                placeholder="1"
                min="1"
                max="100"
                @change="changeQuantity"
              />
            </span>
            <span class="number-countarrow">
              <div class="count-up">
                <font-awesome-icon icon="chevron-up" @click="quantityUp" />
              </div>
              <div class="count-down">
                <font-awesome-icon icon="chevron-down" @click="quantityDown" />
              </div>
            </span>
          </div>
        </div>

        <div class="stock-value">
          <span>
            <b-button
              v-if="product.__typename != 'GroupedProduct'"
              @click="addToCart()"
              v-bind:disabled="btnDisable"
              :style="buttonClass"
              variant="success"
              class="big-btn"
            >
              <span>{{ $t("buy_product") }}</span>
              <span>
                <font-awesome-icon icon="chevron-circle-right" />
              </span>
            </b-button>
          </span>
        </div>
      </div>

      <div class="product-footer">
        <div class="footer-row-one">
          <div class="footer-detail">
            <span>
              <font-awesome-icon icon="check-circle" class="success" />
            </span>
            &nbsp; {{ $t("lowest_price_garanty") }}
          </div>
          <div class="footer-detail">
            <span>
              <font-awesome-icon icon="check-circle" class="success" />
            </span>
            &nbsp; {{ $t("free_shipping_quote") }}{{ formatCurrency(1000) }}
          </div>
          <div class="footer-detail">&nbsp;</div>
          <div class="footer-detail rating-star-icon">
            <span>
              <font-awesome-icon icon="check-circle" class="success" />
            </span>
            <span class="rating-wrap">
              <div class="rating-star-wrap">
                <span
                  class="stars-active"
                  :style="`width: ${
                    (kiyohRatings.last_12_month_average_rating.toFixed(1) /
                      10) *
                    100
                  }%`"
                >
                  <b-icon icon="star-fill"></b-icon>
                  <b-icon icon="star-fill"></b-icon>
                  <b-icon icon="star-fill"></b-icon>
                  <b-icon icon="star-fill"></b-icon>
                  <b-icon icon="star-fill"></b-icon>
                </span>
                <span class="stars-inactive">
                  <b-icon icon="star"></b-icon>
                  <b-icon icon="star"></b-icon>
                  <b-icon icon="star"></b-icon>
                  <b-icon icon="star"></b-icon>
                  <b-icon icon="star"></b-icon>
                </span>
              </div>
            </span>
            <span
              class="rating"
              v-if="kiyohRatings && kiyohRatings.last_12_month_average_rating"
            >
              {{
                kiyohRatings.last_12_month_average_rating
                  .toFixed(1)
                  .replace(".", ",") + "/10"
              }}
            </span>
            <span class="rating" v-else> 0,0 </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lbLogo from "@/esf_utrecht_bankxl/assets/images/lb_logo.png";
import { isServer } from "@storefront/core/helpers";
import { isMobile, isTablet } from "mobile-device-detect";
import DeliveryTime from "@/esf_utrecht_bankxl/core/components/products/product-detail/DeliveryTime";
import Switcher from "./Switcher.vue";

export default {
  name: "ProductParameters",
  components: {
    DeliveryTime,
    Switcher,
  },
  props: {
    selectedConfigProduct: Object,
  },
  data() {
    return {
      lbLogo,
      quantity: 1,
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentBundle",
      selectedBundles: "product/getSelectedBundles",
      options: "product/getCurrentProductOptions",
    }),
    productUnit() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.unit
        : this.product.unit;
    },
    productBrand() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.brand
        : this.product.brand;
    },
    requestColorUrl() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.sample_request_url
        : this.product.sample_request_url;
    },
    regularPrice() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.price_range.maximum_price.regular_price
            .value
        : this.product &&
            this.product.price_range.maximum_price.regular_price.value;
    },
    finalPrice() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.price_range.maximum_price.final_price.value
        : this.product &&
            this.product.price_range.maximum_price.final_price.value;
    },
    deliveryTime() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.DeliveryTime
        : this.product.DeliveryTime;
    },
    stockStatus() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.stock_status
        : this.product.stock_status;
    },
    stockQty() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.stock_qty
        : this.product.stock_qty;
    },
    currentChildSku() {
      return this.$store.getters["product/getCurrentChildSku"];
    },
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.product.sku
      );
    },
    btnDisable() {
      if (this.product.__typename == "ConfigurableProduct") {
        const retval = this.currentChildSku;
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    getCurrentUrl() {
      if (!isServer) {
        return window.location.href;
      }
      return "";
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      } else {
        return false;
      }
    },
    modalText() {
      var modal = this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "modal_content_montage"
      );
      return modal;
    },
    montage() {
      var exists =
        this.product.layout_features &&
        this.product.layout_features.indexOf("assembly") != -1;
      return exists;
    },
    showroom() {
      var exists =
        this.product.layout_features &&
        this.product.layout_features.indexOf("showroom") != -1;
      return exists;
    },
    switcherConfig() {
      return this.product.switcher_configuration;
    },

    kiyohRatings() {
      return this.$store.getters["home/getKiyohRatings"];
    },
  },
  methods: {
    getColor(value) {
      if (this.product.__typename == "ConfigurableProduct") {
        const parameter = this.productConfig.configurable_options.find(
          (element) => element.attribute_code === "kleur"
        );

        if (parameter != null) {
          if (this.productConfig.configurable_options.length === 1) {
            const variant = this.productConfig.variants.find(
              (el) => el.product.sku === value
            );

            const newVal = variant.attributes[0].value_index;
            const object = parameter.values.find(
              (element) => element.value_index == newVal
            );
            if (object != null) {
              return object.swatch_data.value;
            } else {
              return null;
            }
          } else {
            const object = parameter.values.find(
              (element) => element.value_index == value
            );
            return object.swatch_data.value;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    async updateWish() {
      if (this.isInWishlist) {
        await this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.product.sku,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          await this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.currentChildSku;
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            //this.$router.push("/" + this.product.url_key+'/');
          } else {
            await this.$store.dispatch("wishlist/addProductToWishlist", {
              sku: childSku,
              parentSku: this.product.sku,
            });
          }
        }

        const msg = {
          type: "success",
          text: this.$t("wishlist success"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(index, value) {
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });

          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            // this.$bvModal.show("modal-1");
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.currentChildSku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
            }
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        case "BundleProduct": {
          const selected_options =
            this.$store.getters["product/getSelectedBundlesOptions"];

          let items =
            "{ quantity: " +
            this.quantity +
            ' sku: "' +
            this.product.sku +
            '" selected_options: ' +
            JSON.stringify(selected_options) +
            " }";

          const retval = await this.$store.dispatch("cart/addProductToCart", {
            items: items,
          });

          if (this.$gtm.enabled()) {
            window.dataLayer.push({
              event: "eec.add",
              ecommerce: {
                add: {
                  products: [
                    {
                      name: this.product.name,
                      id: this.product.sku,
                      quantity: this.quantity,
                    },
                  ],
                },
              },
            });
            if (this.$gtm.ga4Enabled) {
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.product.final_price,
                    items: [this.getGTMItems()],
                  },
                },
              });
            }
          }
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        default:
          break;
      }
      this.quantity = 1;
    },
    scroll(elId) {
      const el = document.getElementById(elId);
      if (el) {
        const offset = el.offsetTop;
        if (this.mobileCheck == true) {
          scroll({
            top: offset - 110,
            behavior: "smooth",
          });
        } else {
          scroll({
            top: offset - 200,
            behavior: "smooth",
          });
        }
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
    changeQuantity(event) {
      const qty = event.target.value;
      if (qty >= 1) {
        this.quantity = qty;
      } else {
        this.quantity = 1;
      }
    },
    clickOk() {
      this.$bvModal.hide("product-delete-wish");
      this.updateWish();
    },
    clickCancel() {
      this.$bvModal.hide("product-delete-wish");
    },
    showPopup() {
      this.$refs["product-delete-wish"].show();
    },
  },
};
</script>

<style></style>
