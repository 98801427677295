<template>
  <div>
    <div v-if="editAddressId == 0">
      <div class="account-address-book">
        <h4 class="add-title">{{ $t("address_information") }}</h4>
        <div class="address-block">
          <!-- <h2>{{ $t("default_addresses") }}</h2> -->
          <b-row>
            <b-col sm="6">
              <div class="single-address">
                <div class="address-title">
                  <h3>{{ $t("default_billing_address") }}</h3>
                  <b-link
                    class="btn btn-success"
                    @click="goToEditAddress(defaultBilling.id)"
                    >{{ $t("edit_address") }}
                  </b-link>
                </div>
                <div class="no-address" v-if="defaultBilling == null">
                  {{ $t("no_default_billing_address") }}
                </div>
                <address v-if="defaultBilling != null">
                  <div>
                    {{ defaultBilling.firstname }} {{ defaultBilling.lastname }}
                  </div>
                  <div v-if="defaultBilling.company != null">
                    {{ defaultBilling.company }}
                  </div>
                  <div>
                    {{ defaultBilling.street.join(" ") }}
                  </div>
                  <div>
                    {{ defaultBilling.city }}, {{ defaultBilling.postcode }}
                  </div>
                  <div>{{ defaultBilling.country_code }}</div>
                  <div>
                    T:
                    <span
                      ><a :href="`tel:` + defaultBilling.telephone">
                        {{ defaultBilling.telephone }}</a
                      ></span
                    >
                  </div>
                  <div v-if="defaultBilling.vat_id != null">
                    {{ $("VAT") + ":" + defaultBilling.vat_id }}
                  </div>
                </address>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="single-address">
                <div class="address-title">
                  <h3>{{ $t("default_shipping_address") }}</h3>
                  <b-link
                    class="btn btn-success"
                    @click="goToEditAddress(defaultShipping.id)"
                    >{{ $t("edit_address") }}
                  </b-link>
                </div>

                <div class="no-address" v-if="defaultShipping == null">
                  {{ $t("no_default_shipping_address") }}
                </div>
                <address v-if="defaultShipping != null">
                  <div>
                    {{ defaultShipping.firstname }}
                    {{ defaultShipping.lastname }}
                  </div>
                  <div v-if="defaultShipping.company != null">
                    {{ defaultShipping.company }}
                  </div>
                  <div>
                    {{ defaultShipping.street.join(" ") }}
                  </div>
                  <div>
                    {{ defaultShipping.city }}, {{ defaultShipping.postcode }}
                  </div>
                  <div>{{ defaultShipping.country_code }}</div>
                  <div>
                    T:<a :href="`tel:` + defaultShipping.telephone">
                      {{ defaultShipping.telephone }}</a
                    >
                  </div>
                  <div v-if="defaultShipping.vat_id != null">
                    VAT:{{ defaultShipping.vat_id }}
                  </div>
                </address>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="address-block">
          <h2 class="add-title">{{ $t("other_addresses") }}</h2>
          <div class="address-table-wrap">
            <p v-if="addressList && addressList.length === 0">
              {{ $t("no_add_other_address") }}
            </p>

            <div class="address-action">
              <div
                class="add-address-row"
                v-for="address in addressList"
                :key="address.id"
              >
                <address v-if="defaultShipping != null">
                  <div>
                    {{ address.firstname }}
                    {{ address.lastname }}
                  </div>
                  <div v-if="address.company != null">
                    {{ address.company }}
                  </div>
                  <div>
                    {{ address.street.join(" ") }}
                  </div>
                  <div>{{ address.city }}, {{ address.postcode }}</div>
                  <div>{{ address.country_code }}</div>
                  <div>
                    T:<a :href="`tel:` + address.telephone">
                      {{ address.telephone }}</a
                    >
                  </div>
                  <div v-if="address.vat_id != null">
                    VAT:{{ address.vat_id }}
                  </div>
                </address>
                <div class="action-wrap">
                  <b-link class="btn btn-success" @click="Adjust(address.id)">{{
                    $t("edit_address")
                  }}</b-link>
                  <b-link
                    class="btn btn-secondary"
                    @click="Remove(address.id)"
                    v-if="
                      !(
                        address.default_billing == true ||
                        address.default_shipping == true
                      )
                    "
                    >{{ $t("delete") }}</b-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-footer-wrap login-btn">
          <b-button variant="success" @click="newAddress()">
            {{ $t("add_address") }}
          </b-button>
          <b-button variant="cancel" @click="goBack()">{{
            $t("back")
          }}</b-button>
        </div>
      </div>
    </div>
    <AccountEditAddress v-if="editAddressId != 0" />
  </div>
</template>

<script>
import AccountEditAddress from "@/esf_utrecht_bankxl/core/components/account/AccountEditAddress.vue";

export default {
  name: "AccountAddressBook",
  components: { AccountEditAddress },
  data() {
    return {
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "firstname",
          label: this.$t("form_first_name"),
        },
        {
          key: "lastname",
          label: this.$t("form_last_name"),
        },
        {
          key: "street[0]",
          label: this.$t("form_street"),
        },
        {
          key: "city",
          label: this.$t("form_city"),
        },
        {
          key: "country_code",
          label: this.$t("form_country"),
        },
        {
          key: "postcode",
          label: this.$t("form_postal_code"),
        },
        {
          key: "telephone",
          label: this.$t("form_phone"),
        },
        {
          key: "btn",
          label: "",
        },
      ],
    };
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
        window.scrollTo({
          top: 0,
        });
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    addressList() {
      if (typeof this.user.addresses != "undefined") {
        var addresses = [];
        this.user.addresses.forEach((address) => {
          if (
            address.id != this.defaultBilling.id &&
            address.id != this.defaultShipping.id
          ) {
            addresses.push(address);
          }
        });
        return addresses;
      }
      return [];
    },
  },

  methods: {
    newAddress() {
      this.editAddressId = -1;
    },
    Adjust(id) {
      this.$store.commit("user/setEditAddressId", id);
    },
    Remove(id) {
      this.$store.dispatch("user/deleteAddress", { id: id });
    },
    goToEditAddress(id) {
      //this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goBack() {
      this.$store.commit("user/setEditAddresses", false);
    },
  },
  mounted() {
    var self = this;
    window.onpopstate = function (event) {
      event.preventDefault();
      self.$store.commit("user/setEditAddresses", false);
    };
    document.documentElement.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss" scoped></style>
