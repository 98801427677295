<template>
  <div class="wishlist-product-table pb-20">
    <h3 class="product-heading pb-25">{{ $t("compose") }}</h3>
    <table class="product-parameter-table">
      <tr>
        <th>{{ $t("Article") }}</th>
        <th>{{ $t("no_of") }}</th>
        <th>{{ $t("price_per_piece") }}</th>
        <th>{{ $t("subtotal") }}</th>
        <th>{{ $t("stock") }}</th>
        <th>{{ $t("delivery_time_text") }}</th>
        <!-- <th>Sub Total</th> -->
      </tr>
      <tr v-for="(item, index) in grouped" :index="index" :key="index">
        <td>
          <div class="first-col">
            <div class="first-col-img">
              <b-img
                :src="item.product.image.thumbnail"
                :alt="item.product.product_name"
                class="wishlist-prd"
              />
            </div>
            <div class="first-col-content">
              {{ item.product.name }}
              <DeliveryTime
                :data="deliveryTime"
                v-if="stockStatus !== 'IN_STOCK'"
              />
              <p class="stuck-info stuck-size bold pb-15" v-else>
                <span>
                  {{ $t("in_stock")
                  }}<!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} -->
                </span>
              </p>
            </div>
          </div>
        </td>
        <td>
          <div class="stock-cont-div">
            <div class="number-count-inner">
              <span class="number-count">
                <input
                  class="stock-count"
                  type="number"
                  :value="item.qty"
                  min="1"
                  max="100"
                  @change="changeQuantity($event, index)"
                />
              </span>
              <span class="number-countarrow">
                <div class="count-up" @click="quantityUp(index)">
                  <font-awesome-icon icon="chevron-up" />
                </div>
                <div class="count-down" @click="quantityDown(index)">
                  <font-awesome-icon icon="chevron-down" />
                </div>
              </span>
            </div>
          </div>
        </td>
        <td>
          {{
            formatCurrency(
              item.product.price_range.maximum_price.final_price.value
            )
          }}
        </td>
        <td>
          {{
            formatCurrency(
              item.product.price_range.maximum_price.final_price.value *
                item.qty
            )
          }}
        </td>
        <td>
          <p class="stuck-info">
            <span v-if="product.stock_status === 'IN_STOCK'">
              {{ $t("in_stock") }}: {{ product.stock_qty }} {{ $t("pieces") }}
            </span>
            <span v-else> {{ $t("out_of_stock") }}</span>
          </p>
        </td>
        <td>
          <p class="success lever-txt text-center text-16">
            {{ item.product.DeliveryTime.short }}
          </p>
        </td>
      </tr>
    </table>

    <div class="total-price">
      <div>
        <span class="price-text pr-3">{{ $t("total_price") }}</span>
        <span class="price-text">{{ formatCurrency(total) }}</span>
      </div>
      <div class="cost-text">
        {{ $t("collection_delivery") }}
      </div>
      <div class="total-price-btns">
        <b-button
          :disabled="hasZeroQty"
          variant="success"
          class="big-btn"
          @click="submitProduct"
          ><span class="pr-1 text-16">{{ $t("add_to_cart") }}</span
          ><span><font-awesome-icon icon="chevron-circle-right" /></span
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
export default {
  name: "BundleProductTypeComponent",

  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      grouped: "product/getCurrentGrouped",
      total: "product/getGroupedTotalPrice",
    }),
    hasZeroQty() {
      if (this.grouped) {
        return this.grouped.every((el) => el.qty === 0);
      }
      return true;
    },
    /*
    ...mapMutations({
      grouped: "product/setCurrentGrouped",
    }),
    */
  },
  methods: {
    changeQuantity(event, id) {
      const qty = event.target.value;
      if (qty >= 0) {
        this.grouped[id].qty = qty;
      } else {
        this.grouped[id].qty = 0;
      }
    },
    quantityUp(index) {
      this.grouped[index].qty++;
    },
    quantityDown(index) {
      if (this.grouped[index].qty > 0) this.grouped[index].qty--;
    },
    async submitProduct() {
      let items = "[ ";
      this.grouped.forEach((element) => {
        if (element.qty > 0) {
          items =
            items +
            '{ sku:"' +
            element.product.sku +
            '" quantity:' +
            element.qty +
            "} ";
        }
      });
      items = items + "]";
      Logger.debug("items", "BundleProductType", items)();
      let retval = await this.$store.dispatch("cart/addProductToCart", {
        type: this.product.__typename,
        items: items,
      });
      Logger.debug("submitProduct", "BundleProductType", retval)();
      // this.$bvModal.show("modal-1");
      this.$root.$emit("openCartPopup");
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
  },
};
</script>
