<template>
  <div class="mb-4">
    <div class="d-flex justify-content-around">
      <b-img
        src="@/esf_utrecht_bankxl/assets/images/ideal.png"
        alt="iDeal"
        width="50"
      />
      <b-img
        src="@/esf_utrecht_bankxl/assets/images/mastercard.png"
        alt="Mastercard"
        width="50"
      />
      <b-img
        src="@/esf_utrecht_bankxl/assets/images/visa.png"
        alt="Visa"
        width="50"
      />
      <b-img
        src="@/esf_utrecht_bankxl/assets/images/applepay.png"
        alt="Apple Pay"
        width="50"
      />
    </div>
    <hr />
    <div class="checkout-kiyoh">
      Beoordeling door klanten:
      <span class="review-author text-center"
        ><b-img :src="kiyohlogo" width="15" /> Kiyoh</span
      >
      <span class="img-rating-box">
        <span class="default-widget-rating-number">
          <span
            class="rating"
            v-if="kiyohRatings && kiyohRatings.last_12_month_average_rating"
          >
            {{
              kiyohRatings.last_12_month_average_rating
                .toFixed(1)
                .replace(".", ",")
            }}
            / 10
          </span>
          <span class="rating" v-else> 0,0 </span>
        </span>
        <b-link
          class="star-bottom"
          href="https://www.kiyoh.com/reviews/1041467/homingxl"
        >
          <span class="review-count text-center">
            <span
              class="text-10 mr-1"
              v-if="kiyohRatings && kiyohRatings.number_reviews"
            >
              - {{ kiyohRatings.number_reviews }}
              {{
                kiyohRatings.number_reviews == 1
                  ? "beoordeling"
                  : "beoordelingen"
              }}
            </span>
            <span class="text-10" v-else> 0 beoordelingen</span>
          </span>
        </b-link>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShippingInformationBlock",
  data() {
    return {
      kiyohlogo: require("../../../assets/images/kiyoh.svg"),
    };
  },
  computed: {
    kiyohRatings() {
      return this.$store.getters["home/getKiyohRatings"];
    },
    rateStars() {
      let starCount = 0;
      if (this.kiyohRatings) {
        const averageRating = this.kiyohRatings.last_12_month_average_rating;
        starCount = averageRating / 2;
      }
      return new Array(5).fill(false).map((_, i) => i < parseInt(starCount));
    },
  },
};
</script>
